<template>
  <v-card class="ma-5">
    <v-toolbar extended>
      <v-toolbar-title>Cov-Cares Stoplight Survey</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined class="ml-2" @click="help = !help">{{ help ? 'Hide' : 'Show' }} Help</v-btn>
      <template v-slot:extension>
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
        <span style="min-width:100px;text-align:right">{{ termLabel }}</span>
      </template>
    </v-toolbar>
    <v-alert v-if="loading" type="info" class="mt-2">Loading Students...</v-alert>
    <v-card-text v-else class="mt-2">
      <v-card v-if="help" class="ma-2 mt-1" elevation="2">
        <v-card-title>Stoplight Survey Help</v-card-title>
        <v-card-text>
          <p>Please select your concern level for each student below. The concern levels are:</p>
          <p class="ml-2">
            <v-icon color="success" small>fas fa-circle</v-icon> Green: No concerns (Default).<br/>
            <v-icon color="warning" small>fas fa-circle</v-icon> Yellow: Not yet having significant difficulty, but perhaps showing early signs of struggle and may need intervention later in the semester.<br/>
            <v-icon color="error" small>fas fa-circle</v-icon> Red: Already showing signs that he/she is not likely to successfully complete the class without intervention.<br/>
          </p>
          <p>Both the medium and high concern options will give you a box where you can choose or enter a reason for your concern. These are generic areas of concern; keep anything specific in the comments.</p>
          <p>To add a reason that is not in the list, type in your text and press enter. This will not add it to the list to choose for other reasons, but does add it to that student. Please keep this brief and add more details in the comments.</p>
          <p>You can also add comments separate from the general concern reasons, where you can enter anything specific to that individual. Click on the <v-icon small>fal fa-comments</v-icon> icon to open the comment window. If the icon is shown as <v-icon small>fas fa-comments</v-icon> then there is already at least one comment on the student. Note that this will only show the comments that you have entered.</p>
          <p>You can also make changes. So if you want to return to the survey at a later date to update your concern level or to add additional comments on a student you can do that. Just follow the same procedure to open the survey and it should pre-populate with the information from the previous submission.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined @click="help = false">Hide Help</v-btn>
        </v-card-actions>
      </v-card>
      <stoplight-row
        v-for="(student, index) in students"
        :key="'stu-' + student.pidm"
        ref="stoplightRow"
        :student="student"
        :concerns="concerns"
        @change="(val) => updateStudent(index, val)"
        @addComment="(val) => addComment(index, val)"
      ></stoplight-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save()">
        <v-icon left>fal fa-save</v-icon>
        Save Survey
      </v-btn>
    </v-card-actions>
    <v-btn fab fixed bottom right color="success" @click="save()">
      <v-icon>fal fa-save</v-icon>
    </v-btn>
  </v-card>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    StoplightRow: () => import('@/components/cov-cares/stoplightRow')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('cov-cares/stoplight')
    const id = computed(() => root.$route.params.id)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const term = ref('')
    const crn = ref('')
    const title = ref('')
    const surveyId = ref('')
    const termLabel = ref('')
    const students = ref([])
    const concerns = ref([])
    const loading = ref(true)

    onMounted(async () => {
      // Find the term that has the open stoplight survey
      const { data: termData } = await root.$feathers.service('system/term').find({ query: { 'stoplight.open': { $lte: new Date() }, 'stoplight.close': { $gte: new Date() } } })
      if (termData.length === 0) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'No term was found with an open stoplight survey.' })
        root.$router.push('/cov-cares')
        return
      }
      term.value = termData[0].term
      const query = { term: term.value }

      const pidmHash = {}
      const arr = []
      const pidms = []
      const { data: classData } = await root.$feathers.service('calendar/classes').find({ query: { _id: id.value } })
      if (classData.length > 0) {
        const { term: trm, crn: c, title: t, students: courseStu } = classData[0]
        term.value = trm
        crn.value = c
        title.value = t
        query.crn = c
        root.$feathers.service('system/term').find({ query: { term } }).then(({ data }) => {
          if (data.length > 0) {
            termLabel.value = data[0].label
          }
        })
        courseStu.forEach(({ pidm, name, regStatus }) => {
          if (regStatus === 'RE' || regStatus === 'RW') {
            pidms.push(pidm)
            pidmHash[pidm] = { pidm, name, regStatus, concern: 'N', reasons: [], comments: [] }
          }
        })
        concerns.value = await root.$store.dispatch('cov-cares/loadStoplightConcernOptions', false)
      } else {
        const { data } = await root.$feathers.service('work-study/placements').find({ query: { _id: id.value } })
        if (data.length > 0) {
          const { code, name } = data[0]
          query.placement = code
          title.value = name
          const year = parseInt(term.value.substring(2, 4))
          let aidy = (term.value.substring(4, 6) === '60' ? year : (year - 1)) * 101 + 1
          const { data: stuData } = await root.$feathers.service('work-study/students').find({ query: { code, aidy } })
          stuData.forEach(({ pidm, name }) => {
            pidms.push(pidm)
            pidmHash[pidm] = { pidm, name, concern: 'N', reasons: [], comments: [] }
          })
          concerns.value = await root.$store.dispatch('cov-cares/loadStoplightConcernOptions', true)
        } else {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'The survey group could not be loaded. Please contact Technology Services for assistance.' })
          root.$router.push('/cov-cares')
          return
        }
      }
      const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: pidms }, $select: ['pidm', 'email', 'photo'], $limit: 50 } })
      for (const { pidm, email, photo } of dirData) {
        if (pidm in pidmHash) {
          pidmHash[pidm].email = email
          pidmHash[pidm].photo = photo
        }
      }
      const { data } = await service.find({ query })
      if (data.length > 0) {
        const { _id, survey } = data[0]
        surveyId.value = _id
        for (const { pidm, concern, reasons, comments } of survey) {
          pidmHash[pidm] = { ...pidmHash[pidm], concern, reasons, comments }
        }
      }
      for (const pidm in pidmHash) {
        arr.push(pidmHash[pidm])
      }
      arr.sort(
        (a, b) => a.name < b.name ? -1 : 1
      )
      students.value = arr
      loading.value = false
    })

    function updateStudent (index, { concern, reasons, comments }) {
      const obj = { ...students.value[index] }
      if (concern) obj.concern = concern
      if (reasons) obj.reasons = reasons
      if (comments) obj.comments = comments
      students.value.splice(index, 1, obj)
    }
    function addComment (index, comment) {
      students.value[index].comments.push(comment)
    }

    const stoplightRow = ref([])

    async function save () {
      let isValid = true
      for (const slRow of stoplightRow.value) {
        if (!slRow.validate()) {
          if (isValid) {
            // This is the first error; scroll the page to put this row element into view
            console.log(slRow.$el.offsetTop)
            window.scrollTo(0, slRow.$el.offsetTop)
            isValid = false
          }
        }
      }
      if (!isValid) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'All High Concern levels are required to enter concern reasons', timeout: 8000 })
        return
      }
      const pidmHash = {}
      let arr = []
      if (surveyId.value !== '') {
        const data = await service.get(surveyId.value)
        if (data.survey.length > 0) {
          arr = data.survey
          for (let i = 0; i < arr.length; i++) {
            pidmHash[arr[i].pidm] = i
          }
        }
      }
      for (const { pidm, name, concern, reasons, comments } of students.value) {
        if (pidm in pidmHash) {
          const index = pidmHash[pidm]
          let history = { }
          let changed = false
          if (arr[index].concern !== concern) {
            history.concern = arr[index].concern
            arr[index].concern = concern
            changed = true
          }
          if (JSON.stringify(arr[index].reasons) !== JSON.stringify(reasons.join(','))) {
            history.reasons = arr[index].reasons
            arr[index].reasons = reasons
            changed = true
          }
          if (JSON.stringify(arr[index].comments) !== JSON.stringify(comments)) {
            arr[index].comments = comments
            changed = true
          }
          if (changed) {
            if (!('history' in arr[index])) arr[index].history = []
            arr[index].history.push({ ...history, user: user.value.username, date: new Date() })
          }
        } else {
          if (concern !== 'N' || comments.length > 0) {
            arr.push({ pidm, name, concern, reasons, comments })
          }
        }
      }
      if (surveyId.value === '') {
        try {
          await service.create({ term: term.value, crn: crn.value, user: user.value.name, survey: arr })
          root.$store.dispatch('main/snackbar', { active: true, type: 'success', text: 'Stoplight survey saved successfully', timeout: 6000 })
          root.$router.push('/cov-cares/stoplight')
        } catch (e) {
          console.log(e)
          root.$store.dispatch('main/snackbar', { active: true, type: 'error', text: 'Error creating stoplight survey: ' + e, timeout: 10000 })
        }
      } else {
        try {
          await service.patch(surveyId.value, { survey: arr })
          root.$store.dispatch('main/snackbar', { active: true, type: 'success', text: 'Stoplight survey updated successfully', timeout: 6000 })
          root.$router.push('/cov-cares/stoplight')
        } catch (e) {
          console.log(e)
          root.$store.dispatch('main/snackbar', { active: true, type: 'error', text: 'Error updating stoplight survey: ' + e, timeout: 10000 })
        }
      }
    }

    const help = ref(false)

    return {
      term,
      crn,
      title,
      termLabel,
      students,
      concerns,
      loading,
      updateStudent,
      addComment,
      stoplightRow,
      save,
      help
    }
  }
}
</script>
